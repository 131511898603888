// sekcja ze szczegółami na podsumowaniu

import React, { FC } from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import map from 'lodash/map';

import { usePostOrderEditCancel, usePostOrderEditConfirm } from 'api';
import { IOrderVmp, IOrderServicesVmpResponse } from 'api/types';
import { useAppNavigate } from 'hooks';

import { Button } from 'components/controls';

import styles from 'theme/pages/Order/components/OrderDetails/OrderDetails.module.scss';

// typ danych wejściowych
interface IProps {
  orderId: number;
  orderData: IOrderVmp;
  isEditingMode: boolean;
  ordersServicesData?: IOrderServicesVmpResponse;
}

const OrderDetails: FC<IProps> = ({ orderId, orderData, isEditingMode, ordersServicesData }) => {
  const navigate = useAppNavigate();

  const { mutate: orderCancel } = usePostOrderEditCancel(orderId, {
    onSuccess: () => {
      navigate('/dashboard/orders');
    }
  });

  const { mutate: orderConfirm } = usePostOrderEditConfirm(orderId, {
    onSuccess: () => {
      navigate('/dashboard/orders');
    }
  });

  return (
    <div
      className={classnames(
        styles.componentWrapper,
        'StylePath-Pages-Checkout-Components-Details'
      )}>
      {ordersServicesData?.items.map((service) => (
        <div key={service.id} className={styles.row}>
          <div className={styles.column}>
            <div className={styles.title}>{service.name}</div>
          </div>
          <div className={styles.column}>{service.description}</div>
          <div className={styles.column}>
            {service.total_price_net_formatted?.replace('.', ',')} {service.currency}
          </div>
        </div>
      ))}

      <div className={styles.actionsWrapper}>
        <div className={styles.priceWrapper}>
          <div className={styles.valueTotalNet}>
            <div>
              <Trans>Wartość netto</Trans>
            </div>
            <div>
              {orderData?.value_net_formatted ? (
                <>
                  <span>
                    {orderData.value_net_formatted.replace('.', ',')} {orderData.currency}
                  </span>{' '}
                </>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          {map(orderData?.tax_details?.tax_list, (tax) => (
            <div className={styles.grossValue}>
              <div>
                <Trans>VAT</Trans> {tax.tax_rate}%
              </div>
              <div>
                {tax.total_sum_tax_formatted?.replace('.', ',')} {orderData?.currency}
              </div>
            </div>
          ))}

          <div className={styles.valueTotalGross}>
            <div>
              <Trans>Wartość brutto</Trans>
            </div>
            <div>
              {orderData?.value_gross_formatted ? (
                <>
                  {/* {orderData.old_value_gross_formatted && (
                    <span className={styles.oldPrice}>
                      {orderData.old_value_gross_formatted.replace('.', ',')} {orderData.currency}
                    </span>
                  )}{' '} */}
                  <span
                  // className={classnames(orderData.old_value_gross_formatted && styles.discount)}
                  >
                    {orderData.value_gross_formatted.replace('.', ',')} {orderData.currency}
                  </span>{' '}
                </>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>
          {/* {orderData?.discount_value_formatted && (
            <div className={styles.totalDiscount}>
              <Trans>Oszczędzasz</Trans>{' '}
              <b>
                {orderData.discount_value_formatted.toString().replace('.', ',')}{' '}
                {orderData.currency}
              </b>
            </div>
          )} */}
        </div>
      </div>
      {isEditingMode && (
        <div className={styles.orderActions}>
          <Button ghost onClick={() => orderCancel()}>
            <Trans>Anuluj zmiany</Trans>
          </Button>
          <Button onClick={() => orderConfirm()}>
            <Trans>Zapisz zmiany</Trans>
          </Button>
        </div>
      )}
    </div>
  );
};

export default OrderDetails;
