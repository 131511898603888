import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import classnames from 'classnames';

import { useNotifications } from 'hooks';
import {
  useGetOrderExportImages,
  useGetOrderExportDocuments,
  useGetOrdersEan,
  useGetOrderExport
} from 'api';
import { IOrderVmp } from 'api/types';
import { Loader } from 'components/controls';

import styles from 'theme/pages/Order/components/OrderSummary/OrderSummary.module.scss';

interface IProps {
  orderData: IOrderVmp;
}

const DownloadSection: FC<IProps> = ({ orderData }) => {
  const { t } = useTranslation();
  const { showErrorMessage } = useNotifications();

  // loader
  const [isLoading, setIsLoading] = useState(false);

  // export zamówienia do pdf
  const { refetch: downloadPdf } = useGetOrderExport(
    orderData.id || 0,
    { export_type: 'pdf' },
    {
      enabled: false,
      onSuccess: (data) => {
        const a = document.createElement('a');
        a.download = data.file_name;
        a.href = `data:application/pdf;base64,${data.content}`;
        a.click();
      }
    }
  );

  const { refetch: downloadImages } = useGetOrderExportImages(orderData.id, {
    enabled: false,
    onSuccess: (data) => {
      fetch(data.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;

          a.download = data.file_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);

          setIsLoading(false);
        })
        .catch(() => showErrorMessage(t('Błąd pobierania pliku')));
    },
    onError: () => {
      setIsLoading(false);
    }
  });

  // export zamówienia do pdf
  const { refetch: getDocuments } = useGetOrderExportDocuments(
    orderData.id,
    {},
    {
      enabled: false,
      onSuccess: (data) => {
        const a = document.createElement('a');
        a.download = data.file_name;
        a.href = data.url;
        a.click();
      }
    }
  );
  const handleClick = () => {
    setIsLoading(true);
    downloadImages();
  };

  const { refetch: getEan } = useGetOrdersEan(
    orderData.id,
    {},
    {
      enabled: false,
      onSuccess: (data) => {
        const a = document.createElement('a');
        a.download = data.file_name;
        a.href = `data:application/csv;base64,${data.content}`;
        a.click();
      }
    }
  );

  return (
    <Grid className={classnames(styles.box, styles.download)} item xs={12} lg={4}>
      <div className={styles.inner}>
        <div className={styles.boxTitle}>
          <Trans>Pobierz</Trans>
        </div>
        {orderData.has_document_files && (
          <div>
            <button onClick={() => getDocuments()}>
              <Trans>Faktury</Trans>
            </button>
          </div>
        )}
        <div>
          <button disabled={isLoading} onClick={() => getEan()}>
            <Trans>EAN</Trans>
            {isLoading && <Loader />}
          </button>
          <button disabled={isLoading} onClick={() => handleClick()}>
            <Trans>Zdjęcia</Trans>
            {isLoading && <Loader />}
          </button>
          <button disabled={isLoading} onClick={() => downloadPdf()}>
            <Trans>Karta zamówienia</Trans>
            {isLoading && <Loader />}
          </button>
        </div>
      </div>
    </Grid>
  );
};

export default DownloadSection;
