// strona detali zamówienia

import React, { useMemo, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { Grid } from '@mui/material';
import { reduxActions, useDispatch } from 'store';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames';

import { useGetOrderVmp, useGetPanelOrder, useGetOrdersServicesVmp } from 'api';
import { OrderHeader, OrderSummary, OrderPositions, OrderDetails } from './components';
import { Loader } from 'components/controls';

import styles from 'theme/pages/Order/Order.module.scss';

interface IProps {
  id?: number;
}

const DashboardOrder: FC<IProps> = ({ id }) => {
  // ID zamówienia (przekształcony na int)
  const { orderId: orderIdParam } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ustawienie breadcrumbs'ów po zamontowaniu komponentu
  useEffect(() => {
    dispatch(
      reduxActions.setBreadcrumbs([
        { name: t('Moje konto'), path: '/dashboard' },
        { name: t('Lista zamówień'), path: '/dashboard/orders' },
        { name: `${orderIdParam}` }
      ])
    );
  }, []);

  const { isEditingMode } = qs.parse(location.search, { parseBooleans: true });

  const orderId = useMemo(() => id || parseInt(orderIdParam || ''), [orderIdParam, id]);

  const {
    data: orderData,
    isLoading,
    refetch: refetchOrderData
  } = (id ? useGetPanelOrder : useGetOrderVmp)(
    orderId,
    {
      showEditingData: (isEditingMode as boolean) || false
    },
    {
      onSuccess: ({ is_editable }) => {
        if (!is_editable && isEditingMode) {
          navigate(location.pathname, { replace: true });
        }
      },
      onError: (data) => {
        if (data.status === 0) {
          navigate(id ? '/managment_panel/dynamic_page/PANEL_ORDERS' : '/dashboard/orders', {
            replace: true
          });
        }
      }
    }
  );

  // pobieranie danych informacyjnych
  const { data: ordersServicesData, refetch: refetchOrdersServicesData } = useGetOrdersServicesVmp(
    orderId,
    {
      showEditingData: !!isEditingMode
    }
  );

  if (!orderData || isLoading) {
    return <Loader />;
  }

  return (
    <div className={classnames(styles.wrapperComponent, 'StylePath-Pages-Order')}>
      <Grid columnSpacing="32px">
        <Grid item>
          <OrderHeader />
          <OrderSummary
            orderData={orderData}
            refetchOrderData={refetchOrderData}
            isEditingMode={(isEditingMode as boolean) || false}
          />
          <OrderPositions
            onChange={() => {
              refetchOrderData();
              refetchOrdersServicesData();
            }}
            orderId={orderId}
            isEditingMode={(isEditingMode as boolean) || false}
          />
          <OrderDetails
            orderId={orderId}
            ordersServicesData={ordersServicesData}
            orderData={orderData}
            isEditingMode={(isEditingMode as boolean) || false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardOrder;
